import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <div className="not-found-pg">
    <Layout>
      <div>
        <h1 className="has-text-centered">404 NOT FOUND</h1>
        <p className="has-text-centered">Sorry! The page you're looking for doesn't exist! </p>
      </div>
    </Layout>
  </div>
)

export default NotFoundPage
